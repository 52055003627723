import React from 'react';

function RetryForm({ submitHandler, formState }) {
  return (
    <React.Fragment>
      {/* <div>There was an error submitting your form.</div> */}
      <div>Please check your connection and try again.</div>
      <br />
      <button
        className="form--button-retry form--button"
        onClick={submitHandler}
        data-formstate={formState}
      >
        Try again
      </button>
    </React.Fragment>
  );
}

export default React.memo(RetryForm);
