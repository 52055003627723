// import React from 'react';
import { cold } from 'react-hot-loader';

import FormFieldBase from './FormField';
import FormButtonBase from './FormButton';
import FormFieldPrettyBase from './FormFieldPretty';
import FormSelectPrettyBase from './FormSelectPretty';
import FormSelectBase from './FormSelect';
import FormCheckboxBase from './FormCheckbox';
import FormFieldFileBase from './FormFieldFile';

export const FormField = cold(FormFieldBase);
export const FormFieldFile = cold(FormFieldFileBase);
export const FormFieldPretty = cold(FormFieldPrettyBase);
export const FormButton = cold(FormButtonBase);
export const FormSelectPretty = cold(FormSelectPrettyBase);
export const FormSelect = cold(FormSelectBase);
export const FormCheckbox = cold(FormCheckboxBase);
