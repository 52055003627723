import React from 'react';
import PropTypes from 'prop-types';

import { defaultValidator, selectDefaultValidityMessage } from './validation';

export default class FormField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.resetValidity = this.resetValidity.bind(this);
    this.getValue = this.getValue.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      isValid: true,
      validationMessage: this.getValidationMessage(),
    };
  }

  getValue() {
    return this.inputRef.current.value;
  }

  getValidationMessage() {
    const { validationMessage, name, required } = this.props;
    if (validationMessage) return validationMessage;

    return selectDefaultValidityMessage(name, required);
  }

  validate() {
    const value = this.getValue();
    const { validator, name, required } = this.props;
    let isValid;
    if (validator) {
      isValid = validator(value);
    } else {
      isValid = defaultValidator(name, value, required);
    }

    this.setState({ isValid });
    return isValid;
  }

  resetValidity() {
    this.setState({ isValid: true });
  }

  render() {
    const { name, initialValue, label = false, type = 'text', required = false } = this.props;
    const { validationMessage, isValid } = this.state;

    const labelComponent = label ? (
      <label className="form--label" htmlFor={name}>
        {label}
      </label>
    ) : null;

    const hiddenStyle = {
      visibility: 'hidden',
      margin: 'unset',
      padding: 'unset',
      height: '0',
    };

    return (
      <React.Fragment>
        <div className="form--row" style={type === 'hidden' ? hiddenStyle : {}}>
          {labelComponent}
          <div className="form--input-wrapper">
            <input
              className="form--input"
              required={required}
              type={type}
              name={name}
              ref={this.inputRef}
              defaultValue={initialValue}
              onBlur={this.validate}
              onClick={this.resetValidity}
              data-validity={isValid}
            />
            <div className="form--validation" data-validity={isValid}>
              {validationMessage}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  initialValue: PropTypes.string,
  validator: PropTypes.func,
  validationMessage: PropTypes.string,
};
