import React from 'react';
import PropTypes from 'prop-types';
import FormFieldPretty from './FormFieldPretty';

import { defaultValidator, selectDefaultValidityMessage } from './validation';

export default class FormSelect extends FormFieldPretty {
  constructor(props) {
    super(props);

    // this.state = {
    //   isValid: false,
    //   validationMessage: this.getValidationMessage(),
    // };
  }

  parseChildren() {}

  render() {
    const { children, name, size, required, multiple } = this.props;
    const { validationMessage, isValid } = this.state;

    return (
      <div className="form--select-row">
        <div className="form--select-wrapper">
          <select
            className="form--select"
            name={name}
            required={required}
            size={size}
            multiple={multiple}
            ref={this.inputRef}
            onFocus={this.clickHandler}
            onBlur={this.blurHandler}
          >
            {children}
          </select>
          <div className="form--select-validation" data-validity={isValid}>
            {validationMessage}
          </div>
        </div>
      </div>
    );
  }
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.string,
  validator: PropTypes.func,
  validationMessage: PropTypes.string,
};

FormSelect.defaultProps = {
  size: 1,
  required: false,
  multiple: false,
};
