import React from 'react';
// import PropTypes from 'prop-types';

import Form from '../registration/Form';
import { FormFieldFile, FormSelectPretty } from '../registration/coldComponents';
import FormButton from '../registration/FormButton';

const ENDPOINT_BASE = 'http://localhost:5000/subtleinsights/us-central1/recieveUpload/';

export default class UploadEndpointChooser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler() {
    console.log("endpoint chooser's submit handler");
    // console.log(this);
    // console.log(this.formRef.current)

    const { operation, table, csv } = this.formRef.current.getInputValues();
    const endpoint = `${ENDPOINT_BASE}${operation.toLowerCase()}/${table.toLowerCase()}`;

    const formData = new FormData();
    const file = csv['0'];

    formData.append('csv', file);
    return fetch(endpoint, {
      method: 'POST',
      body: formData,
    })
      .then(body => body.text())
      .then(results => {
        // convert from json to obj
        results = JSON.parse(results);

        if (Array.isArray(results)) {
          console.log(results);
        } else if (typeof results === 'object') {
          Object.keys(results).forEach(key => {
            console.log(`${key}:`);
            console.log(results[key]);
          });
        } else {
          console.log(results);
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <Form ref={this.formRef} submit={this.submitHandler} action="/">
          <FormSelectPretty name="operation" label="action">
            <option>Append</option>
            <option>Insert</option>
            <option>Update</option>
            <option>Upsert</option>
            <option>Get</option>
          </FormSelectPretty>
          <FormSelectPretty name="table" label="table">
            <option>Contacts</option>
            <option>Hospitals</option>
            <option>Records</option>
            <option>Execs</option>
            <option>Hospitals-with-execs</option>
            {/* <option>Campaigns</option> */}
          </FormSelectPretty>
          <FormFieldFile name="csv" required />
          <FormButton
            type="submit"
            value="Process"
            style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
          />
        </Form>
        {/* <div style={{ textAlign: 'center' }} onClick={this.submitHandler}>
          Process
        </div> */}
      </React.Fragment>
    );
  }
}
