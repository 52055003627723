import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => ({
  signedIn: state.user.signedIn,
  isAuthorized: state.user.isAuthorized,
});

class SigninSwitch extends React.PureComponent {
  render() {
    const { signedIn, isAuthorized, children } = this.props;
    if (signedIn !== true) return null;
    if (!isAuthorized) {
      return (
        <div className="admin--no-permissions">You don't have permissions to access this page.</div>
      );
    }

    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default connect(
  mapStateToProps,
  null
)(SigninSwitch);
